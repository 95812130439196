import React, {Component} from 'react';
import { AutoForm } from 'uniforms-mui';
import { HiddenField, SubmitField, AutoField, ErrorsField } from 'uniforms-mui';
import { bridge as schema } from './schema/triggers.jsx';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Container from '@mui/material/Container';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import BackToTop from './custom/BackToTop';
import Header from './custom/Header';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {getParams, saveOption, loadProfilePics, selectChannel} from './common/ChannelUtil';

class AutoCounters extends Component{
    
    constructor(props) {
        super(props);
	const channelName = sessionStorage.getItem('channelName');
	this.state = {
	    triggerData: {},
	    counters: [],
	    showSuccess: false,
	    showError: false,
	    channelName: channelName,
	    pics: {},
	    displayNames: {},
	    inProgress: false
	};
    }

    loadChannelInfo(channel, me){
	me.loadTriggers(channel, me);
    }

    
    componentDidMount(){
	const params = getParams();
	if(params != null){
	    const channelName = params.channelName;
	    const accessToken = params.accessToken;
	    this.loadTriggers(channelName, accessToken);
	}
    }

    loadTriggers(channelName, that){
	let triggerData = null;
	const me = this || that;
	const userName = sessionStorage.getItem('userName');
	const accessToken = sessionStorage.getItem('accessToken');
	this.setState({inProgress: true});
	axios.get('https://api.mycroft.gg/triggers?channelName='
		  +channelName+'&userName='+userName,
		  {
		      headers: {
			  accessToken: accessToken
		      }
		  }).then((response)=>{

		      triggerData = response.data;
		      if(triggerData.error){
			  me.setState({error: triggerData.error});
			  return;
		      }else{
			  me.setState({error: false});
		      }

		      let counters = Object.keys(triggerData.counters) || [];
		      counters = counters.map(counter => {
			  return { label: counter, value: counter};
		      });
		      console.log(counters);
		      me.setState({triggerData: triggerData});
		      me.setState({counters: counters});
		      saveOption(channelName);
		      const modedChannelsStr = sessionStorage.getItem("modedChannels");
		      const modedChannels = JSON.parse(modedChannelsStr);
		      const modedChannelNames = modedChannels.map((ch)=>ch.value);
		      loadProfilePics([...modedChannelNames,channelName], me);
		  }).catch((error)=>{
		      console.log("error");
		      console.log(error);
		      me.setState({error: "You're either not a mod for this channel or the channel is not using Mycroft."});
		  }).finally(()=>{
		      this.setState({inProgress: false});
		  });
    }
    
    render() {
	console.log(this.state.triggerData);
	const params = getParams();
	if(!params){
	    return <Navigate to="./login" replace={true} />;
	}else{
	    const modedChannelsStr = sessionStorage.getItem("modedChannels");
	    const modedChannels = JSON.parse(modedChannelsStr);
	    const me = this;
	    const onSelect = function(event){
		selectChannel(event.target.value, me);
		return true;
	    };
	    if(this.state.inProgress){
		return (
		    <Container maxWidth="md">
			
			<Header channelName={this.state.channelName}
				displayNames={this.state.displayNames}
				pic={this.state.pics[this.state.channelName]}
				modedChannels={modedChannels}
				onSelect={onSelect}
				pageName={"Auto Counters"}
			/>
			<Box>
			    <CircularProgress />
			</Box>
		    </Container>		    
		);
	    }

	    return (
		this.state.error &&
		    <Container maxWidth="md">
			
			<Header channelName={this.state.channelName}
				displayNames={this.state.displayNames}
				pic={this.state.pics[this.state.channelName]}
				modedChannels={modedChannels}
				onSelect={onSelect}
				pageName={"Auto Counters"}
			/>
			<Alert severity="error">
			    {this.state.error}
			</Alert>
		    </Container>
	    ) ||
		( !this.state.error &&
		  <Container maxWidth="md">
		      <Header channelName={this.state.channelName}
			      displayNames={this.state.displayNames}
			      pageName={"Auto Counters"}
			      pic={this.state.pics[this.state.channelName]}
			      modedChannels={modedChannels}
			      onSelect={onSelect}
		      />
		      
		      <AutoForm
			  schema={schema}
			  model={this.state.triggerData}
			  onSubmit={(model: any) => this.onSubmit(model)}>

			  <br />
			  <HiddenField name="channelName" />
			  <Stack spacing={4}>
			      <Accordion>
				  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
				      {"Counter for Bit Donations"}
				  </AccordionSummary>
				  <AutoField name="triggers.bitDonation.minBits" />
				  <AutoField name="triggers.bitDonation.counter" options={this.state.counters}/>
				  <AutoField name="triggers.bitDonation.increment" />
			      </Accordion>


			      <Accordion>
				  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
				      {"Counter for Subscriptions"}
				  </AccordionSummary>
			      <AutoField name="triggers.subscription.counter" options={this.state.counters}/>
			      <AutoField name="triggers.subscription.increment" />
			      </Accordion>


			      <Accordion>
				  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
				      {"Counter for Channel Point Redeems"}
				  </AccordionSummary>
			      <AutoField name="triggers.redeem.redeemName" />
			      <AutoField name="triggers.redeem.counter" options={this.state.counters}/>
			      <AutoField name="triggers.redeem.increment" />
			      </Accordion>
			   			      

			      <Accordion>
				  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
				      {"Counter for Follows"}
				  </AccordionSummary>
			      <AutoField name="triggers.follow.counter" options={this.state.counters}/>
			      <AutoField name="triggers.follow.increment" />
			      </Accordion>
			   			      

			      <Accordion>
				  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
				      {"Counter for Raid"}
				  </AccordionSummary>
			      <AutoField name="triggers.raid.counter" options={this.state.counters}/>
			      <AutoField name="triggers.raid.increment" />
			      </Accordion>

                              <AutoField name="triggers.hype" />
			   			      
			      <ErrorsField />
			      <SubmitField value="Save"/>
			      <BackToTop children={true} />
			  </Stack>
		      </AutoForm>


		      <Grid item>
			  <Snackbar open={this.state.showSuccess} autoHideDuration={3000}>
			      <Alert severity="success">
				  Changes saved successfuly
			      </Alert>
			  </Snackbar>

			  <Snackbar open={this.state.showError} autoHideDuration={3000}>
			      <Alert severity="error">
				  Error saving changes
			      </Alert>
			  </Snackbar>
		      </Grid>
		  </Container>
		);
	}
    }

    onSubmit(changedModel){
	const model = Object.assign({}, changedModel);
	const accessToken = sessionStorage.getItem('accessToken');
	const channelName = sessionStorage.getItem('channelName');
	const userName = sessionStorage.getItem('userName');	

	const me = this;
	this.setState({inProgress: true});
	axios.put('https://api.mycroft.gg/triggers?channelName='+channelName+'&userName='+userName, model, {
	    headers: {
		accessToken: accessToken
	    }
	}).then((response)=>{
	    //console.log(response);
	    if(response.status === 200){
		me.setState({
		    showSuccess: true,
		    showError: false
		});
		window.setTimeout(()=>{
		    me.setState({
			showSuccess: false,
			showError: false
		    });
		}, 3000);
	    }
	}).catch((error)=>{
	    me.setState({
		showError: true,
		showSuccess: false
	    });
	    window.setTimeout(()=>{
		me.setState({
		    showSuccess: false,
		    showError: false
		});
	    }, 3000);	    
	}).finally(()=>{
	    this.setState({inProgress: false});
	    this.loadTriggers(channelName, me);
	});
    }
}

export default AutoCounters;
