import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';

export default function Header({channelName, pageName, displayNames, pic, modedChannels, onSelect}){
    const [state, setState] = React.useState({
	menuOpen: false
    });

    const toggleDrawer = (open) => (event) => {
	if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
	    return;
	}

	setState({ ...state, ["menuOpen"]: open });
    };
    
    const list = (anchor) => (
	<Box
	    role="presentation"
	    onClick={toggleDrawer(false)}
	    onKeyDown={toggleDrawer(false)}
	>
	    <List>
		<ListItem component={Link} href="#/settings">
		    <ListItemButton>
                        <ListItemText primary="General"/>
		    </ListItemButton>
		</ListItem>
		<ListItem component={Link} href="#/loyalty">
		    <ListItemButton>
                        <ListItemText primary="Loyalty"/>
		    </ListItemButton>
		</ListItem>
		<ListItem component={Link} href="#/autoCounters">
		    <ListItemButton>
                        <ListItemText primary="AutoCounters"/>
		    </ListItemButton>
		</ListItem>
	    </List>
	</Box>
    );
    
    return (
	<AppBar position="sticky">
            <Toolbar>
		<React.Fragment key={"left"}>
		    <IconButton
			size="large"
			edge="start"
			color="inherit"
			aria-label="menu"
			sx={{ mr: 2 }}
			onClick={toggleDrawer(true)}
		    >
		      <MenuIcon />
		    </IconButton>
		    <Drawer
			anchor="left"

			open={state["menuOpen"]}
			onClose={toggleDrawer(false)}
		    >
			{list("left")}
		    </Drawer>
		</React.Fragment>
		
		<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
		    Mycroft Settings / {pageName}
		</Typography>

		{channelName && (
		    <TextField select
			       onChange={onSelect}
			       value={channelName}
			       InputProps={{
				   startAdornment: (
				       <InputAdornment position="start">
					   <Avatar alt={channelName}
						   src={pic}/>
				       </InputAdornment>
				   )
			       }}
		    >
			{
			    modedChannels.map(item=>{
				return <MenuItem key={item.value} value={item.value}>{displayNames[item.value]}</MenuItem>;
			    })
			}
		    </TextField>
		)}
		
            </Toolbar>
	</AppBar>
    );
}
